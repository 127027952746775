import React, { useRef, useState, useEffect } from "react";
import moment from "moment/moment";
import Swal from "sweetalert2";
import upiImage from "../upiImage.jpg";
import logo from "../logo black.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

const GeneratePdfInvoice = () => {
  const optionsForTwo = {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  };
  const optionsForOne = {
    maximumFractionDigits: 1,
    minimumFractionDigits: 1,
  };

  const pdfRef = useRef();

  const [errMsg, setErrMsg] = useState("");
  const [invoiceNo, setInvoiceNo] = useState("");
  const [currentDate, setCurrentDate] = useState(new Date());
  const [dueDate, setdueDate] = useState(new Date());
  const [customerName, setCustomerName] = useState("");
  const [customerLineFirst, setCustomerLineFirst] = useState("");
  const [customerLineSecond, setcustomerLineSecond] = useState("");
  const [customerLandmark, setCustomerLandmark] = useState("");
  const [customerCity, setCustomerCity] = useState("Ahmedabad");
  const [customerState, setCustomerState] = useState("Gujarat");
  const [customerPincode, setCustomerPincode] = useState("");
  const [customerGst, setCustomerGst] = useState("");
  const [placeOfSupply, setPlaceOfSupply] = useState("24-GUJARAT");
  const [itemList, setItemList] = useState([]);
  const [item, setItem] = useState("");
  const [rate, setRate] = useState(0.0);
  const [qty, setQty] = useState(0);
  const [sac, setSac] = useState("");
  const [gst, setGst] = useState(0.0);
  const [totalTax, seTotalTax] = useState(0.0);
  const [totalTaxableAmount, setTotalTaxableAmount] = useState(0.0);

  useEffect(() => {
    setTotalTaxableAmount(0.0);
    seTotalTax(0.0);
    if (itemList.length > 0) {
      // eslint-disable-next-line
      itemList.map((item) => {
        setTotalTaxableAmount(
          (totalTaxableAmount) =>
            parseFloat(item.itemTaxble) + parseFloat(totalTaxableAmount)
        );
        seTotalTax(
          (totalTax) => parseFloat(item.taxAmount) + parseFloat(totalTax)
        );
      });
    }
    setErrMsg("");
    // eslint-disable-next-line
  }, [
    invoiceNo,
    dueDate,
    customerName,
    customerLandmark,
    customerLineFirst,
    customerLineSecond,
    customerCity,
    customerState,
    customerPincode,
    customerGst,
    placeOfSupply,
    itemList,
  ]);

  useEffect(() => {
    if (errMsg !== "") {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: errMsg,
      }).then(function () {
        setErrMsg("");
      });
    }
    // eslint-disable-next-line
  }, [errMsg]);

  const addItem = () => {
    let error = "";
    if (!item) {
      error = "Add Item Name";
    }
    if (rate === 0) {
      error = "Add rate";
    }
    if (qty === 0) {
      error = "Add rate";
    }
    if (!sac) {
      error = "Add SAC";
    }
    if (gst === 0) {
      error = "Add GST";
    }
    if (error) {
      setErrMsg(error);
    } else {
      setItemList((itemList) => [
        ...itemList,
        {
          itemName: item,
          itemRate: parseFloat(rate),
          itemQty: parseFloat(qty),
          itemSac: sac,
          itemGst: parseFloat(gst),
          itemTaxble: parseFloat(rate * qty),
          taxAmount: parseFloat((gst * qty * rate) / 100),
          amount: parseFloat(rate * qty + (gst * qty * rate) / 100),
        },
      ]);
      setItem("");
      setRate(0.0);
      setQty(0.0);
      setSac("");
      setGst("");
    }
  };

  const handleSubmit = () => {
    let error = "";
    if (invoiceNo === "") {
      error = "Add Invoice No.";
    }
    if (dueDate === "") {
      error = "Add Invoice Due Date.";
    }
    if (customerName === "") {
      error = "Add Customer Name.";
    }
    if (customerLineFirst === "" && customerLineSecond === "") {
      error = "Add Customer Address.";
    }
    if (customerCity === "") {
      error = "Add Customer City.";
    }
    if (customerState === "") {
      error = "Add Customer State.";
    }
    if (customerPincode === "") {
      error = "Add Customer Pincode.";
    }
    if (placeOfSupply === "") {
      error = "Add Place Of Supply.";
    }
    if (itemList.length === 0) {
      error = "Add Items.";
    }
    if (error === "") {
      grtPdf();
    } else {
      setErrMsg(error);
    }
  };

  const removeItem = (item) => {
    Swal.fire({
      icon: "warning",
      title: "Warning",
      text: "Are you sure you want to remove this Item?",
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: "Yes",
    }).then(function (result) {
      if (result.isConfirmed) {
        setItemList(
          itemList.filter((items) => items.itemName !== item.itemName)
        );
      }
    });
  };

  const grtPdf = () => {
    const input = pdfRef.current;

    const pdfWidth = 210;
    const margin = 0;

    html2canvas(input, { scale: 1.5 }) // Increase the scale for better resolution
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const imgProps = {
          width: canvas.width,
          height: canvas.height,
        };
        const imgWidth = pdfWidth - 2 * margin;
        const imgHeight = (imgProps.height * imgWidth) / imgProps.width;

        const pdf = new jsPDF("p", "mm", [pdfWidth, imgHeight + 2 * margin]);

        pdf.addImage(imgData, "PNG", margin, margin, imgWidth, imgHeight);
        pdf.save(
          `ShivKrupaAC_${invoiceNo + "_" + moment().format("DD_MM_YYYY")}`
        );
      })
      .catch((error) => {
        console.error("Error generating PDF", error);
      });
  };

  return (
    <>
      <div className="container">
        <div
          ref={pdfRef}
          className="pdf-content p-5"
          style={{ border: "1px solid gray" }}
        >
          <section className="content">
            <div className="row justify-content-center">TAX INVOICE</div>
          </section>
          <section className="content">
            <div className="row">
              <div className="col-12">
                <h4>
                  <img
                    src={logo}
                    className="mt-3"
                    height={180}
                    width={450}
                    alt="logo"
                  />
                  <small className="float-right">ORIGINAL FOR RECIPIENT</small>
                </h4>
              </div>
            </div>
          </section>
          <section className="content">
            <div className="row justify-content-between">
              <div className="col-sm-4">
                <b>GSTIN :- 24CDDPG6235K1ZM</b>
                <br />
                52, 1ST FLOOR, INDRAJITBAG CO.OP.H.SOC.,
                <br />
                OPP.DIAMOND SILK MILLS,
                <br />
                NICOL ROAD, THAKKARBAPA NAGAR
                <br />
                Ahmedabad, GUJARAT, 382350
                <br />
                Mobile :- 8238638933
              </div>
              <div className="col-sm-4">
                <br />
                <b>Invoice No. :- </b> {invoiceNo}
                <br />
                <b>Invoice Date :- </b>
                {currentDate.getDate() +
                  "/" +
                  (currentDate.getMonth() + 1) +
                  "/" +
                  currentDate.getFullYear()}
                <br />
                <b>Invoice Due Date :- </b>
                {dueDate.getDate() +
                  "/" +
                  (dueDate.getMonth() + 1) +
                  "/" +
                  dueDate.getFullYear()}
              </div>
            </div>
          </section>
          <br />
          <section className="content">
            <div className="row">
              <div className="col-sm-4">
                <b>Customer Details:</b>
                <br />
                <b>{customerName}</b>
                <br />
                <b>GSTIN : {customerGst}</b>
                <br />
                Place of Supply :- <b>{placeOfSupply}</b>
              </div>

              <div className="col-sm-6">
                <b>Billing address:</b>
                <br />
                {customerLineFirst === "" ? "" : customerLineFirst + ","}
                {customerLineSecond === "" ? "" : customerLineSecond + ","}
                {customerLandmark === "" ? "" : customerLandmark + ","}
                <br />
                {customerCity},{customerState},{customerPincode}
              </div>
            </div>
          </section>
          <section className="content mt-4">
            <div className="row">
              <div className="col-sm-12">
                <table className="table">
                  <thead
                    style={{
                      border: "4px solid #e7afe7",
                      background: "#e7afe7",
                    }}
                  >
                    <tr
                      style={{
                        border: "4px solid #e7afe7",
                        background: "#e7afe7",
                      }}
                    >
                      <th style={{ background: "#e7afe7" }}>#</th>
                      <th style={{ background: "#e7afe7" }}>Item</th>
                      <th style={{ background: "#e7afe7" }}>Rate/Item</th>
                      <th style={{ background: "#e7afe7" }}>Qty</th>
                      <th style={{ background: "#e7afe7" }}>Taxable Value</th>
                      <th style={{ background: "#e7afe7" }}>Tax Amount</th>
                      <th style={{ background: "#e7afe7" }}>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {itemList.length > 0 &&
                      itemList.map((itemData, index) => (
                        <tr
                          key={index}
                          onClick={() => removeItem(itemData)}
                          style={{ borderTop: "2px solid #e7afe7" }}
                        >
                          <td>{index + 1}</td>
                          <td>
                            <b> {itemData.itemName}</b> <br />
                            SAC:{itemData.itemSac}
                          </td>
                          <td>
                            <b>
                              {Intl.NumberFormat("en-IN", optionsForTwo).format(
                                itemData.itemRate
                              )}
                            </b>
                          </td>
                          <td>
                            {Intl.NumberFormat("en-IN", optionsForOne).format(
                              itemData.itemQty
                            )}
                          </td>
                          <td>
                            {Intl.NumberFormat("en-IN", optionsForTwo).format(
                              itemData.itemTaxble
                            )}
                          </td>
                          <td>
                            {Intl.NumberFormat("en-IN", optionsForTwo).format(
                              itemData.taxAmount
                            )}
                            <br />(
                            {Intl.NumberFormat("en-IN", optionsForOne).format(
                              itemData.itemGst
                            )}
                            %)
                          </td>
                          <td>
                            {Intl.NumberFormat("en-IN", optionsForTwo).format(
                              itemData.amount
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </section>
          <section className="content text-right">
            <div className="row justify-content-end">
              <div className="col-sm-2">
                <b>Taxable Amount</b>
              </div>
              <div className="col-sm-2">
                ₹
                {Intl.NumberFormat("en-IN", optionsForTwo).format(
                  totalTaxableAmount
                )}
              </div>
            </div>
            <div className="row justify-content-end">
              <div className="col-sm-2">CGST</div>
              <div className="col-sm-2">
                ₹
                {Intl.NumberFormat("en-IN", optionsForTwo).format(totalTax / 2)}
              </div>
            </div>
            <div className="row justify-content-end">
              <div className="col-sm-2">SGST</div>
              <div className="col-sm-2">
                ₹
                {Intl.NumberFormat("en-IN", optionsForTwo).format(totalTax / 2)}
              </div>
            </div>
            <div className="row justify-content-end">
              <div
                className="col-sm-2 text-lg"
                style={{ borderTop: "4px solid #e7afe7" }}
              >
                <b>Total</b>
              </div>
              <div
                className="col-sm-2 text-lg"
                style={{ borderTop: "4px solid #e7afe7" }}
              >
                <b>
                  ₹
                  {Intl.NumberFormat("en-IN", optionsForTwo).format(
                    totalTaxableAmount + totalTax
                  )}
                </b>
              </div>
            </div>
          </section>

          <section
            className="content mt-4"
            style={{ borderTop: "4px solid #e7afe7" }}
          >
            <div className="row mt-4">
              <div className="col-sm-3">
                <b>Pay Using UPI:</b>

                <img
                  src={upiImage}
                  className="mt-3"
                  height={180}
                  width={180}
                  alt="upiImage"
                />
              </div>

              <div className="col-sm-6">
                <b>Bank Details:</b>

                <div className="row mt-3">
                  <div className="col-sm-3">Bank: </div>
                  <div className="col-sm-6">
                    <b>Axis Bank</b>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-3"> Account No: </div>
                  <div className="col-sm-6">
                    <b>921020038865048</b>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-3">IFSC:</div>
                  <div className="col-sm-6">
                    <b>UTIB0000664</b>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-3">Branch</div>
                  <div className="col-sm-6">
                    <b>BAPUNAGAR, AHMEDABAD</b>
                  </div>
                </div>
              </div>
              <div className="col-sm-3 text-sm text-left">
                For SHIVKRUPA A.C. SALES & SERVICES
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                Authorized signatory
              </div>
            </div>
          </section>
        </div>
        <div className="row justify-content-center m-2">
          <button
            type="button"
            className="btn btn-primary "
            onClick={handleSubmit}
          >
            <i className="fas fa-download"></i> Generate PDF
          </button>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className="card card-primary">
              <div className="card-header">Add Invoice Details</div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="invoiceNo">Invoice No</label>
                          <input
                            type="text"
                            id="invoiceNo"
                            onChange={(e) => setInvoiceNo(e.target.value)}
                            value={invoiceNo}
                            placeholder="Invoice No"
                            required
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="invoiceDueDate">Invoice Date</label>
                          <br />
                          <DatePicker
                            showIcon
                            id="invoiceDueDate"
                            className="form-control"
                            onChange={setCurrentDate}
                            selected={currentDate}
                            placeholder="Invoice Due Date"
                            dateFormat="dd/MM/yyyy"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="invoiceDueDate">
                            Invoice Due Date
                          </label>
                          <br />
                          <DatePicker
                            showIcon
                            id="invoiceDueDate"
                            className="form-control"
                            onChange={setdueDate}
                            selected={dueDate}
                            minDate={new Date()}
                            placeholder="Invoice Due Date"
                            dateFormat="dd/MM/yyyy"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="card card-primary">
              <div className="card-header">Add Customer Details</div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="customerName">Customer Name</label>
                          <input
                            type="text"
                            id="customerName"
                            onChange={(e) => setCustomerName(e.target.value)}
                            value={customerName}
                            placeholder="Customer Name"
                            required
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="customerGst">Customer GST</label>
                          <input
                            type="text"
                            id="customerGst"
                            onChange={(e) => setCustomerGst(e.target.value)}
                            value={customerGst}
                            placeholder="Customer Gst"
                            required
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="customerLineFirst">
                            Customer Address Line 1
                          </label>
                          <input
                            type="text"
                            id="customerLineFirst"
                            onChange={(e) =>
                              setCustomerLineFirst(e.target.value)
                            }
                            value={customerLineFirst}
                            placeholder="Line 1"
                            required
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="customerLineSecond">
                            Customer Address Line 2
                          </label>
                          <input
                            type="text"
                            id="customerLineSecond"
                            onChange={(e) =>
                              setcustomerLineSecond(e.target.value)
                            }
                            value={customerLineSecond}
                            placeholder="Line 2"
                            required
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="customerLandmark">Landmark</label>
                          <input
                            type="text"
                            id="customerLandmark"
                            onChange={(e) =>
                              setCustomerLandmark(e.target.value)
                            }
                            value={customerLandmark}
                            placeholder="Landmark"
                            required
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="customerCity">City</label>
                          <input
                            type="text"
                            id="customerCity"
                            onChange={(e) => setCustomerCity(e.target.value)}
                            value={customerCity}
                            placeholder="City"
                            required
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="customerState">State</label>
                          <input
                            type="text"
                            id="customerState"
                            onChange={(e) => setCustomerState(e.target.value)}
                            value={customerState}
                            placeholder="State"
                            required
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="customerPincode">Pincode</label>
                          <input
                            type="text"
                            id="customerPincode"
                            onChange={(e) => setCustomerPincode(e.target.value)}
                            value={customerPincode}
                            placeholder="Pincode"
                            required
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="placeOfSupply">Place Of Supply</label>
                          <input
                            type="text"
                            id="placeOfSupply"
                            onChange={(e) => setPlaceOfSupply(e.target.value)}
                            value={placeOfSupply}
                            placeholder="Place Of Supply"
                            required
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="card card-primary">
              <div className="card-header">Add Item</div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="item">Item</label>
                          <input
                            type="text"
                            id="item"
                            onChange={(e) => setItem(e.target.value)}
                            value={item}
                            placeholder="Item Name"
                            required
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="rate">Rate</label>
                          <input
                            type="text"
                            id="rate"
                            onChange={(e) =>
                              setRate((v) =>
                                !isNaN(e.target.value)
                                  ? e.target.value
                                      .replace(/[^\d.]/g, "")
                                      .replace(/\./, "x")
                                      .replace(/\./g, "")
                                      .replace(/x/, ".") === ""
                                    ? 0
                                    : e.target.value
                                        .replace(/[^\d.]/g, "")
                                        .replace(/\./, "x")
                                        .replace(/\./g, "")
                                        .replace(/x/, ".")
                                  : v
                              )
                            }
                            value={rate}
                            placeholder="Rate"
                            required
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="qty">Qty</label>
                          <input
                            type="text"
                            id="qty"
                            onChange={(e) =>
                              setQty((v) =>
                                !isNaN(e.target.value)
                                  ? e.target.value.replace(/\D/g, "") === ""
                                    ? 1
                                    : e.target.value.replace(/\D/g, "")
                                  : v
                              )
                            }
                            value={qty}
                            placeholder="Quantity"
                            required
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="sac">SAC</label>
                          <input
                            type="text"
                            id="sac"
                            onChange={(e) => setSac(e.target.value)}
                            value={sac}
                            placeholder="SAC"
                            required
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="gst">GST %</label>
                          <input
                            type="text"
                            id="gst"
                            onChange={(e) =>
                              setGst((v) =>
                                !isNaN(e.target.value)
                                  ? e.target.value
                                      .replace(/[^\d.]/g, "")
                                      .replace(/\./, "x")
                                      .replace(/\./g, "")
                                      .replace(/x/, ".") === ""
                                    ? 0
                                    : e.target.value
                                        .replace(/[^\d.]/g, "")
                                        .replace(/\./, "x")
                                        .replace(/\./g, "")
                                        .replace(/x/, ".")
                                  : v
                              )
                            }
                            value={gst}
                            placeholder="GST %"
                            required
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="addItem">Add Item</label>
                          <button
                            className="btn btn-success btn-block"
                            onClick={addItem}
                          >
                            Add Item
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default GeneratePdfInvoice;
